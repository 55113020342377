


















import {
  useFindMasterTop,
  useLocalFilter,
  useMapMasterTypeToOptionAlt,
} from "@/hooks";
import { Option } from "@/models/class/option.class";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class SelectTermOfPayment extends Vue {
  @Prop({ required: true, type: Number, default: 0 }) value!: string;
  @Prop({ required: false, type: Boolean, default: false }) disabled!: boolean;

  useLocalFilter = useLocalFilter;

  options: Option[] = [];
  loading = false;

  created(): void {
    this.fetchOptions();
  }

  onChange(e: string): void {
    this.$emit("input", e);
    this.$emit("change", e);
  }

  fetchOptions(): void {
    this.loading = true;
    useFindMasterTop()
      .then(res => {
        this.options = useMapMasterTypeToOptionAlt(res);
      })
      .finally(() => {
        this.loading = false;
      });
  }
}
